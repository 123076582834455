export const CATEGORIES = {
  all: "Все",
  reclama: "Реклама",
  docReclama: "Документальная реклама",
  korpVideo: "Корпоративное видео",
  FPV: "FPV-съемка",
  graph: "Графика",
  docKino: "Документальное кино",
  clip: "Клипы",
  showreel: "Шоурилы",
  regular: "Регулярный контент",
};

export const TAGS = {
  showreel: "showreel",
  tv: "TV",
  FPV: "FPV",
  // olv: "OLV",
  // clv: "CLV",
  food: "food",
  korp: "корпоративное",
  CG: "CG",
  clip: "клип",
  docKino: "док",
  opening: "opening",
  threeD: "3D",
  // VFX: "VFX",
  // NFT: "NFT",
  digital: "digital",
  // hr: "HR-ролики",
  // pres: "презентации",
  aero: "аэросъемка",
  // extrim: "экстрим",
  // show: "шоу",
  // web: "веб-сериалы",
  // almanah: "альманахи",
  // snippet: "сниппеты",
  // film: "фильмы",
  // visit: "визитки",
  // live: "лайвы",
  promo: "promo",
  regular: "регулярный",
  // explay: "эксплейнеры",
};
