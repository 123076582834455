import { useMediaQuery } from "@mui/material";
import s from "./podcast.module.scss";
import { breakpoints } from "styles/variables";
import VideoPortfolio from "components/VideoPortfolio/VideoPortfolio";

export const Podcast = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <div className={s.wr}>
      <div className={s.wr_img}>
        <VideoPortfolio
          videoSrc="podcast"
          poster="podcast-poster.jpg"
          withModal={false}
        />
      </div>
      <div className={s.content}>
        <h3>проект-подкаст “Портфолио”</h3>

        <p>
          На канале выходит авторский{isMobile && <br />} подкаст
          {isMobile || <br />} “Портфолио” — разговоры с представителями
          креативной индустрии об их пути, карьере и профессии.
        </p>
        <Button />
      </div>
    </div>
  );
};

const Button = () => (
  <a
    href="https://www.youtube.com/@_sputnik_films/videos"
    target="_blank"
    rel="noopener noreferrer"
    className={s.more}
  >
    <span>→</span> Смотреть
  </a>
);
