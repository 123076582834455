export const tab1 = {
  init: "Работаем с&nbsp;бизнесом напрямую и&nbsp;сотрудничаем с&nbsp;агентствами. Закрываем весь цикл производства и&nbsp;сопутствующие задачи.",
  text_1:
    "More.tv — сделали промо-ролик к&nbsp;выходу нового сериала на&nbsp;платформе",
  text_2:
    "Суши-маркет — сняли рекламу доставки для&nbsp;федерального ТВ. Получили награду «лучший ролик» по&nbsp;версии «Регион Медиа».",
  text_3:
    "Polemica — сделали рекламу международной платформе по&nbsp;игре в&nbsp;«Мафию».",
  text_4:
    "Skuratov Coffee — три года создавали промо-ролики и&nbsp;контент для&nbsp;федеральной сети кофеен.",
  text_5:
    "СберМаркет — сделали серию вертикальных видео для&nbsp;рекламы в&nbsp;«TikTok».",
};

export const tab2 = {
  init: "Работаем в&nbsp;короткой форме и&nbsp;снимаем полнометражные фильмы.",
  text_1:
    "Яндекс — сняли документальные фильмы о&nbsp;водителях такси «Яндекс Go», которые стали частью HR и&nbsp;маркетинговой кампании.",
  text_2:
    "МЕГА — создали спецпроект «Нечего надеть» для&nbsp;Омской «Меги» о&nbsp;преображении женщин со&nbsp;стилистом Ульяной Исаенко.",
};

export const tab3 = {
  init: "Умеем структурировать и&nbsp;компактно преподносить большие объемы информации.",
  text_1:
    "Softline — сделали презентационный ролик о&nbsp;федеральном проекте по&nbsp;строительству пунктов ВГК, который показывают главам регионов.",
  text_2:
    "ОЭМЗ — сняли ролик о&nbsp;сложном производственном процессе, который увеличил ежемесячные продажи цинкования.",
  text_3:
    "РТ-строительство — сделали видео о&nbsp;компании, которое увеличило конверсию сайта и&nbsp;используется в&nbsp;презентациях.",
};
export const tab4 = {
  init: "Снимаем блоги, подкасты, вертикальные видео и прочий контент для брендов.",
  text_1: "Делали видео для соцсетей, снимали коллаборации с блогерами.",
  text_2: "Снимали выпуски и обзоры выставок для YouTube-канала.",
  text_3: "Запускали YouTube-шоу, делали видео для выставок и стендов.",
};
export const tab5 = {
  init: "Снимаем независимое кино и создаем эксклюзивные проекты для брендов и платформ.",
  text_1:
    "Кинопоиск — выпустили фильмы «Там, где&nbsp;наш дом» и&nbsp;«Нескорый поезд».",
  text_2:
    "Okko — доступен веб-сериал «Вы&nbsp;из&nbsp;будущего — 2» и&nbsp;фильм «Там, где&nbsp;наш дом».",
  text_3: "Wink — доступен фильм «Там, где&nbsp;наш дом».",
  text_4: "Tvigle — доступен фильм «Там, где&nbsp;наш дом».",
};
export const tab6 = {
  init: "CG, 2D, 3D, инфографика, монтаж, цветокоррекция",
  text_1:
    "ВТБ — смонтировали и&nbsp;сделали графику для&nbsp;трейлера большого фильма о&nbsp;банке.",
  text_2:
    "Ризолин — сделали 3D-видео о&nbsp;сложных строительных процессах, которое показывают главам регионов.",
  text_3:
    "Вечерний Ургант — придумали заставки к&nbsp;новым рубрикам в&nbsp;шоу.",
  text_4: "МЕГА — сделали заставку для&nbsp;спецпроекта «Нечего надеть».",
  text_5:
    "THEM — сделали продуктовый 3D-ролик, который помог выйти товару на&nbsp;рынок.",
};
