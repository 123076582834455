export const nominations = [
  {
    slug: "cossa.ru",
    url: "https://www.cossa.ru/news/331469/",
    text: "Рынок 90-х, первые деньги и бизнес с нуля: путь предпринимателя в имиджевом ролике WAY GROUP",
    imageSrc: "news_edit-1.png",
  },
  {
    slug: "youtube.com",
    url: "https://youtu.be/jLaXzN5GcCw?si=eC58wu0EGMFw0tWY",
    text: "Финансирование и прокат документального кино | Дмитрий Квашнин | ОРАЗНОМ.MOV",
    imageSrc: "news_edit-2.png",
  },
  {
    slug: "moviestart.ru",
    url: "https://moviestart.ru/2024/06/28/dmitrij-kvashnin-uspeh-regionalnogo-doka-eto-kombinacziya-autentichnogo-settinga-i-universalnoj-istorii/",
    text: "Успех регионального дока – это комбинация аутентичного сеттинга и универсальной истории",
    imageSrc: "news_edit-3.png",
  },
  {
    slug: "ges-2.org",
    url: "https://ges-2.org/beat-film-festival-2024-short-films",
    text: "Авторские короткометражные фильмы Beat Film Festival 2024",
    imageSrc: "news_edit-4.png",
  },
  {
    slug: "gorkyfest.ru",
    url: "https://gorkyfest.ru/contest/tam-gde-nash-dom",
    text: "Там, где наш дом: Хакасия",
    imageSrc: "news_edit-5.png",
  },
  {
    slug: "vremyan.ru",
    url: "https://www.vremyan.ru/news/552256",
    text: "Определены победители VIII кинофестиваля «Горький fest — 2024»",
    imageSrc: "news_edit-6.png",
  },
  {
    slug: "makersofsiberia.com",
    url: "https://makersofsiberia.com/rabotyi/documental-omsk.html",
    text: "Документальное кино — наша отдушина: Дмитрий Квашнин о документалистике в Омске",
    imageSrc: "news_img0-1.png",
  },
  {
    slug: "makersofsiberia.com",
    url: "https://makersofsiberia.com/sreda/mosff-23-republics.html",
    text: "Кино в национальных республиках Сибири. Безвременье, бездорожье и обращение к духам",
    imageSrc: "news_img00.png",
  },
  {
    slug: "calameo.com",
    url: "https://www.calameo.com/read/0067726443ecbfcf6a97c",
    text: "На&nbsp;электричках от&nbsp;Москвы до&nbsp;Владивостока. Интервью с&nbsp;создателями фильма «Нескорый поезд»",
    imageSrc: "news_img01.png",
  },
  {
    slug: "docfilms.space",
    url: "https://docfilms.space/read/talks/kak-montiruyut-dokumentalisty-opyt-treh-rezhisserov/ ",
    text: "Как&nbsp;монтируют документалисты: опыт трех режиссеров",
    imageSrc: "news_img02.png",
  },
  {
    slug: "news.tpu.ru",
    url: "https://news.tpu.ru/news/kino-o-tekhnologiyakh-budushchego-na-fidzhital-dne-proydet-kinopokaz-pod-otkrytym-nebom/",
    text: "Кино о&nbsp;технологиях будущего: на&nbsp;«Фиджитал-дне» пройдет кинопоказ под&nbsp;открытым небом",
    imageSrc: "news_img03.png",
  },
  {
    slug: "moviestart.ru",
    url: "https://moviestart.ru/2023/02/15/na-kinopoiske-vyshel-dokumentalnyj-film-neskoryj-poezd/ ",
    text: "На&nbsp;«Кинопоиске» вышел документальный фильм «Нескорый поезд»",
    imageSrc: "news_img04.png",
  },
  {
    slug: "mc.bk55.ru",
    url: "https://mc.bk55.ru/news/article/26229/",
    text: "Кино омского режиссера стартует во&nbsp;всероссийский прокат",
    imageSrc: "news_img05.png",
  },
  {
    slug: "moviestart.ru",
    url: "https://moviestart.ru/2022/07/11/ulichnye-ekstremalnye-vidy-sporta-budut-predstavleny-v-filme-podderzhannom-fprk/",
    text: "Уличные экстремальные виды спорта будут представлены в&nbsp;фильме, поддержанном ФПРК",
    imageSrc: "news_img06.png",
  },
  {
    slug: "moviestart.ru",
    url: "https://moviestart.ru/2022/09/05/dok-pobeditel-sibirskogo-pitchinga-debyutantov-vyhodit-v-rossijskij-prokat/",
    text: "Док-победитель Сибирского питчинга дебютантов выходит в&nbsp;российский прокат",
    imageSrc: "news_img07.png",
  },
  {
    slug: "moviestart.ru",
    url: "https://moviestart.ru/2022/06/28/dmitrij-kvashnin-i-vladimir-tarasov-dlya-nas-pitching-stal-povorotnym-sobytiem-v-zhizni/",
    text: "Для&nbsp;нас&nbsp;питчинг стал поворотным событием в&nbsp;жизни",
    imageSrc: "news_img08.png",
  },
  {
    slug: "newslab.ru",
    url: "https://lenta.ru/news/2021/07/20/hakasia/",
    text: "Про&nbsp;Хакасию снимут документальный сериал",
    imageSrc: "news_img09.png",
  },
  {
    slug: "ngs55.ru",
    url: "https://ngs55.ru/text/culture/2022/09/04/71627069/",
    text: "Омский режиссер стал соавтором фильма про&nbsp;уникальных сибиряков",
    imageSrc: "news_img10.png",
  },
  {
    slug: "newsnn.ru",
    url: "https://newsnn.ru/news/2022-12-21/dva-nizhegorodskih-goroda-zasvetilis-v-kino-2621451",
    text: "Два&nbsp;нижегородских города засветились в&nbsp;кино",
    imageSrc: "news_img11.png",
  },
];
