import Wrapper from "components/Wrapper";
import s from "./index.module.scss";
import title from "assets/title.svg";
import titleMobile from "assets/titleMobile.svg";
import n72 from "assets/72.svg";
import n84 from "assets/84.svg";
import projects from "assets/projects.svg";
import projectsMobile from "assets/projectsMobile.svg";
import Video from "components/Video/Video";
import Caption from "components/Video/Caption";
import { CATEGORIES, TAGS } from "constants/filters";
import Process from "components/Process";
import cn from "classnames";
import SocialLink from "components/SocialLink";
import TypeIt from "typeit-react";
import { HorizontalTabs } from "components/Tabs/Tabs";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "styles/variables";

const Index = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <section className={s.wr}>
      <Wrapper>
        <h1>
          <img src={isMobile ? titleMobile : title} alt="творчество" />
        </h1>
        <div
          className={s.showreel}
          data-aos={`fade-up`}
          data-aos-offset="0"
          data-aos-duration="1000"
        >
          <Video
            videoSrc={"showreel23"}
            videoUrl={"https://player.vimeo.com/video/935381658?h=c3165f4740"}
            label={"Шоурил 2023"}
            poster={"showreel23-poster.jpg"}
            posterBlur={"showreel23-poster"}
          />
        </div>
        <HorizontalTabs />
        <section className={s.projects}>
          <header data-aos="fade-left" data-aos-offset="0">
            <h2>
              <img src={isMobile ? projectsMobile : projects} alt="Проекты" />
            </h2>
          </header>
          <main>
            <div className={s.videos}>
              <div>
                <div
                  data-aos={`fade-right`}
                  data-aos-duration="1000"
                  data-aos-offset={isMobile ? "100" : "0"}
                >
                  <Video
                    videoSrc={"waygroup"}
                    videoUrl="https://player.vimeo.com/video/935394734?h=10f62926bd"
                    label={"Way Group"}
                    poster={"waygroup-poster.jpg"}
                    posterBlur={"polemica-poster"}
                  />
                </div>
                <Caption
                  tags={["olv"]}
                  category={CATEGORIES.reclama}
                  fade="right"
                >
                  Имиджевый ролик для логистической компании
                </Caption>
              </div>
              <div>
                <div
                  data-aos={`fade-left`}
                  data-aos-duration="1000"
                  data-aos-offset={isMobile ? "100" : "0"}
                >
                  <Video
                    videoSrc={"funkytoys"}
                    videoUrl="https://player.vimeo.com/video/913785752?h=8d20c75124"
                    label={"Funky Toys"}
                    poster={"funkytoys-poster.jpg"}
                    posterBlur={"funkytoys-poster"}
                  />
                </div>
                <Caption tags={["TV"]} category={CATEGORIES.reclama}>
                  Серия ТВ-рекламы для бренда игрушек
                </Caption>
              </div>
            </div>
            <div className={cn(s.polemica, s.video_number)}>
              <div>
                <div
                  data-aos={`fade-left`}
                  data-aos-duration="1000"
                  data-aos-offset={isMobile ? "100" : "0"}
                >
                  <Video
                    videoSrc={"polemica"}
                    videoUrl="https://player.vimeo.com/video/739490263?h=09ec3740bb"
                    label={"polemica"}
                    poster={"polemica-poster.jpg"}
                    posterBlur={"polemica-poster"}
                  />
                </div>
                <Caption
                  tags={["olv", TAGS.digital]}
                  category={CATEGORIES.reclama}
                >
                  Помогли запустить широкую промо-компанию с помощью видео.
                </Caption>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-offset="0"
              >
                <div>
                  <img src={n72} alt="72" />
                </div>
                <span>часа от брифа до результата</span>
              </div>
            </div>
          </main>
        </section>
        <div className={s.process}>
          <Process>
            <span>(процесс)</span>
            {isMobile ? (
              <p>
                Выстраиваем прозрачный и&nbsp;понятный процесс работы.
                Придумываем креатив и&nbsp;защищаем идеи. Обосновываем траты,
                соблюдаем сметы и&nbsp;сроки
                <TypeIt
                  options={{
                    speed: 60,
                    waitUntilVisible: false,
                  }}
                >
                  .
                </TypeIt>
              </p>
            ) : (
              <p>
                Выстраиваем прозрачный и&nbsp;понятный процесс работы.
                Придумываем креатив и&nbsp;защищаем идеи. Обосновываем траты,
                собл
                <TypeIt
                  options={{
                    speed: 60,
                    waitUntilVisible: true,
                  }}
                >
                  юдаем сметы и&nbsp;сроки.
                </TypeIt>
              </p>
            )}
            <figure>
              <div>
                <div>
                  <img
                    src={require(`assets/author-1.png`)}
                    alt="Дмитрий Квашнин"
                  />
                </div>
                <div></div>
              </div>
              <figcaption>
                <span>Дмитрий Квашнин</span>
                <span>Основатель, генеральный продюсер</span>
              </figcaption>
            </figure>
          </Process>
        </div>
        <div className={cn(s.gayane, s.video_number)}>
          <div>
            <div
              data-aos={`fade-right`}
              data-aos-duration="1000"
              data-aos-offset={isMobile ? "100" : "0"}
            >
              <Video
                videoSrc={"gayane"}
                videoUrl="https://player.vimeo.com/video/519605332?h=7dbd2602fc"
                label={"yandex/gayane"}
                poster={"gayane-poster.jpg"}
                posterBlur={"gayane-poster"}
              />
            </div>
            <Caption
              fade="right"
              tags={["brandedcontent"]}
              category={CATEGORIES.docReclama}
            >
              <p>Съёмки проходили в&nbsp;2-х городах.</p>
              <p>1&nbsp;месяц ушёл на&nbsp;производство.</p>
            </Caption>
          </div>
          <div>
            <SocialLink svg={"yandex"} />
            <p>
              Ролик для&nbsp;повышения уровня лояльности пользователей Яндекс.GO
              и&nbsp;проведения HR-компании.
            </p>
          </div>
        </div>
        <div className={cn(s.process, s.process_mob)}>
          <Process>
            <span>(контроль)</span>
            {isMobile ? (
              <p>
                гарантируем удобный сервис и&nbsp;постоянную коммуникацию.
                Утверждаем каждый этап проекта, ведём ежедневные отчёты
                <TypeIt
                  options={{
                    speed: 60,
                    waitUntilVisible: false,
                  }}
                >
                  .
                </TypeIt>
              </p>
            ) : (
              <p>
                гарантируем удобный сервис и&nbsp;постоянную коммуникацию.
                Утверждаем каждый этап проекта,{" "}
                <TypeIt
                  options={{
                    speed: 60,
                    waitUntilVisible: true,
                  }}
                >
                  ведём ежедневные отчёты.
                </TypeIt>
              </p>
            )}
            <figure>
              <div>
                <div>
                  <img
                    src={require(`assets/author-2.png`)}
                    alt="Сергей Клейн"
                  />
                </div>
                <div></div>
              </div>
              <figcaption>
                <span>Сергей Клейн</span>
                <span>Сооснователь, head of production</span>
              </figcaption>
            </figure>
          </Process>
        </div>
        <div className={cn(s.dom, s.video_number)}>
          <div>
            <div
              data-aos={`fade-right`}
              data-aos-duration="1000"
              data-aos-offset={isMobile ? "100" : "0"}
            >
              <Video
                videoSrc={"dom"}
                videoUrl="https://player.vimeo.com/video/759499744?h=55e5aefc8d"
                label={"там, где наш дом"}
                poster={"dom-poster.jpg"}
                posterBlur={"dom-poster"}
              />
            </div>
            <Caption
              fade="right"
              tags={["альманах", "кино"]}
              category={isMobile ? "Док. кино" : CATEGORIES.docKino}
            >
              <p>
                Дебютный документальный проект, ставший победителем
                международных фестивалей.
              </p>
              <p>Кинотеатральный прокат в&nbsp;54 городах СНГ.</p>
            </Caption>
          </div>
          <div data-aos="fade-up" data-aos-offset="0">
            <div>
              <img src={n84} alt="8.4" />
            </div>
            <span>Средняя оценка фильма на&nbsp;Кинопоиске.</span>
          </div>
        </div>
        <div className={cn(s.videos, s.videos_last)}>
          <div>
            <div
              data-aos={`fade-right`}
              data-aos-duration="1000"
              data-aos-offset={isMobile ? "100" : "0"}
            >
              <Video
                videoSrc={"ctrl"}
                videoUrl="https://player.vimeo.com/video/788917724?h=8cb68bdf9b"
                label={"Ctrl + A / add"}
                poster={"ctrl-poster.jpg"}
                posterBlur={"ctrl-poster"}
              />
            </div>
            <Caption
              fade="right"
              tags={[TAGS.tv, "OLV"]}
              category={"Музыкальный клип"}
            ></Caption>
          </div>
          <div>
            <div
              data-aos={`fade-left`}
              data-aos-duration="1000"
              data-aos-offset={isMobile ? "100" : "0"}
            >
              <Video
                videoSrc={"cg"}
                videoUrl="https://player.vimeo.com/video/788918951?h=7d993bb16e"
                label={"шоурил cg"}
                posterBlur={"cg-poster"}
                poster={"cg-poster.jpg"}
              />
            </div>
            <Caption
              tags={[TAGS.tv, "OLV"]}
              category={isMobile ? "Графика" : "Графика и постпродакшн"}
            >
              5 лет развиваем направление
            </Caption>
          </div>
        </div>
      </Wrapper>
    </section>
  );
};

export default Index;
